import { createContext, useCallback, useContext, useState } from "react";
import { useInterval } from "../Hooks/useInterval";

const MAX_NOTIFICATIONS = 4;
const EXPIRE_NOTIFICATION_MS = 4000;

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
    // State
    const [notifications, setNotifications] = useState([]);

    // Add Notification
    const setNotification = useCallback(
        (notification) => {
            const existing = notifications.find((n) => n.id === notification.id);
            const newNotifications = existing ?
                notifications.map((n) => n.id === notification.id ? { ...existing, ...notification } : n)
                : notifications.concat({
                    id: `${Math.random()} ${Date.now()}`,
                    timestamp: new Date().getTime(),
                    ...notification,
                });
            setNotifications(newNotifications);
        },
        [notifications, setNotifications]
    );

    // Clear Notification(s)
    const clearNotifications = useCallback(
        (id) => {
            if (!id) {
                setNotifications([]);
            } else {
                const ids = Array.isArray(id) ? id : [id];
                const newNotifications = notifications.filter(
                    ({ id }) => !ids.includes(id)
                );
                setNotifications(newNotifications);
            }
        },
        [notifications, setNotifications]
    );

    // Expire Notifications
    const handleExpireNotifications = useCallback(
        (currentTime) => {
            if (notifications.length) {
                const expiredIds = notifications.reduce((acc, n) => {
                    return n.timestamp <= currentTime - EXPIRE_NOTIFICATION_MS ?
                        acc.concat(n.id)
                        : acc;
                }, []);
                if (expiredIds.length) {
                    clearNotifications(expiredIds);
                }
            }
        },
        [notifications, clearNotifications]
    )
    
    // Timer
    useInterval(handleExpireNotifications, 1000);

    // Create Provider Value
    const value = {
        notifications,
        setNotification,
        clearNotifications,
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};

// Hook for using context
const useNotifications = () => {
    return useContext(NotificationContext);
};

export { NotificationProvider, useNotifications };