import { useEffect, useState } from "react";
import { useAuth } from "../Providers/AuthProvider";

const Top = () => {
    // State
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [type, setType] = useState("artists");
    const [timeRange, setTimeRange] = useState("short_term");

    // Auth
    const { request } = useAuth();

    // Filter Options
    const typeOptions = ["artists", "tracks"];
    const timeRangeOptions = {
        "short_term": "1 month",
        "medium_term": "6 months",
        "long_term": "12 months",
    };

    // useEffect
    useEffect(() => {
        console.log("requesting");
        request(`top?type=${type}&time_range=${timeRange}`)
        .then(data => {
            console.log(data);
            setItems(data.items);
            setLoading(false);
        });
    }, [type, timeRange]);

    // Handle type change
    const handleTypeChange = (event) => {
        setLoading(true);
        setType(event.target.dataset.type);
    };

    // Handle time range change
    const handleTimeRangeChange = (event) => {
        setLoading(true);
        setTimeRange(event.target.dataset.value);
    };

    // Render Content
    const renderContent = () => {
        if (loading) {
            return <div>Loading...</div>;
        } else {
            return (
                <ol>
                    {
                        type === "artists" ?
                            items.map((item, index) => <TopArtist  key={index} item={item} index={index} />)
                            : items.map((item, index) => <TopTrack key={index} item={item} index={index} />)
                    }
                </ol>
            );
        }
    };

    return (
        <div className="flex justify-center pb-12">
            <div className="rounded min-w-[500px] max-w-3xl">
                <ul className="flex gap-1 text-center font-semibold">
                    {
                        typeOptions.map(typeOption => (
                            <li key={typeOption} data-type={typeOption} className={`grow rounded-t-xl p-3 capitalize ${typeOption === type ? "bg-slate-300" : "bg-slate-500 hover:bg-slate-400"}`} onClick={typeOption === type ? null : handleTypeChange}>{typeOption}</li>
                        ))
                    }
                </ul>
                <ul className="flex gap-1 text-center mt-1 font-semibold">
                    {
                        Object.entries(timeRangeOptions).map(([key, value]) => (
                            <li key={key} data-value={key} className={`grow rounded-t-xl p-3 ${key === timeRange ? "bg-slate-300" : "bg-slate-500 hover:bg-slate-400"}`} onClick={key === timeRange ? null : handleTimeRangeChange}>{value}</li>
                        ))
                    }
                </ul>
                <div className="rounded-b bg-slate-700 p-5 border-t-slate-300 border-t-2 text-white">
                    { renderContent() }
                </div>
            </div>
        </div>
    );
};

const TopArtist = ({ item, index }) => {
    return (
        <li className={`flex gap-2 ${index < 5 ? "text-xl font-bold mb-7" : "text-md font-medium mb-3"}`}>
            <div className="min-w-[30px] text-slate-400">{index + 1}.</div>
            <div className="rounded">
                <img src={item.images[1].url} className={`rounded object-cover ${index < 5 ? "w-16 h-16" : "w-10 h-10"}`} />
            </div>
            <div className="flex items-center">
                <div className="text-slate-100 truncate max-w-xl">{item.name}</div>
            </div>
        </li>
    );
};

const TopTrack = ({ item, index }) => {
    const artists = (item.artists.map(artist => artist.name)).join(", ");

    return (
        <li className={`flex gap-2 ${index < 5 ? "text-xl font-bold mb-7" : "text-md font-medium mb-3"}`}>
            <div className="min-w-[30px] text-slate-400">{index + 1}.</div>
            <div className="rounded">
                <img src={item.album.images[1].url} className={`rounded object-cover ${index < 5 ? "w-16 h-16" : "w-10 h-10"}`} />
            </div>
            <div className="flex-col justify-center">
                <div className="text-slate-100 truncate max-w-xl">{item.name}</div>
                <div className={`text-slate-300 truncate font-normal ${index < 5 ? "text-base" : "text-sm"}`}>{artists}</div>
            </div>
        </li>
    );
};

export default Top;