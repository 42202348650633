import { useCallback, useEffect, useMemo, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useInterval } from "../../Hooks/useInterval";
import { useSpotifyPlaybackSDK } from "../../Hooks/useSpotifyPlaybackSDK";
import { ClassifierArtistGenrePlaylists, ClassifierCustomPlaylists, ClassifierGenrePlaylists, ClassifierLikes, ClassifierPlaybackControls } from "./ClassifierLayout";

const Classifier = () => {
    // State
    const [position, setPosition] = useState(0);

    // Spotify SDK
    const { player, playback, currentTrack, currentTrackId } = useSpotifyPlaybackSDK();
    
    // useEffect - Update Position
    useEffect(() => {
        if (playback) {
            setPosition(playback.position);
        }

        return () => {
            document.title = process.env.REACT_APP_NAME;
        };
    }, [playback]);

    // Update Position
    const updatePosition = useCallback(() => {
        if (playback && !playback.paused && position + 300 <= playback.duration) {
            setPosition(position + 300);
        }
    }, [position, playback]);

    // useInterval - Update Position
    useInterval(updatePosition, 300);

    // No Playback
    if (currentTrackId === null) {
        return (
            <div className="flex-grow flex justify-center items-center">
                <ClipLoader color="#AAAAAA" size="4rem" />
            </div>
        );
    }

    // Collate Artists
    const artists = (currentTrack.artists.map(artist => artist.name)).join(', ');

    // Set Title
    document.title = `${currentTrack.name} - ${artists}`;

    // Current Percent
    const currentPercent = Math.round((position / playback.duration) * 100);

    return (
        <div className="flex flex-wrap gap-5">
            <div className="card card-compact w-96 bg-base-200 shadow-xl">
                <figure>
                    <img src={currentTrack.album.images[2].url} />
                </figure>
                <div id="progress-bar" className="h-1 bg-secondary" style={{width: `${currentPercent}%`}}>

                </div>
                <div className="card-body">
                    <div className="text-xl font-bold truncate">{currentTrack.name}</div>
                    <div className="text-md font-semibold">{artists}</div>
                    <div className="text-sm italic">{currentTrack.album.name}</div>

                    <div className="mt-1">
                        <ClassifierLikes currentTrackId={currentTrackId} />
                    </div>

                    <div className="divider my-1"></div>

                    <ClassifierPlaybackControls player={player} playback={playback} />
                </div>
            </div>

            <div className="flex-1 flex flex-col gap-5">
                <ClassifierArtistGenrePlaylists current={currentTrack} />
                <ClassifierCustomPlaylists currentTrackId={currentTrackId} />
                <ClassifierGenrePlaylists current={currentTrack} />
            </div>
        </div>
    );
};

export default Classifier;