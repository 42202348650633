import { useEffect, useReducer } from "react";
import { useSpotifyPlaylists } from "../Hooks/useSpotify";
import { useAuth } from "../Providers/AuthProvider";
import { ClipLoader } from "react-spinners";

const Settings = () => {
    // Auth
    const { user, request, updateUser } = useAuth();
    
    // State
    const [state, dispatch] = useReducer(SettingsReducer, {
        loading: true,
        error: false,
        playlists: [],
        theme: user.settings.theme ? user.settings.theme : (window.matchMedia("(prefers-color-scheme:dark)").matches ? "dark" : "light"),
    });

    // Playlists
    const { getAllUserOwnedPlaylists } = useSpotifyPlaylists();

    // useEffect
    useEffect(() => {
        dispatch({ type: "fetch" });

        getAllUserOwnedPlaylists()
            .then(data => {
                dispatch({
                    type: "success",
                    playlists: data,
                });
            })
            .catch(() => {
                dispatch({ type: "error" });
            });
    }, []);

    // handlePlaylistSelect
    const handlePlaylistChange = (event) => {
        const id = event.target.value;
        const playlist = state.playlists.filter(playlist => {
            return playlist.id === id;
        })[0];
        request("user/settings", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "playlist_name": playlist.name,
                "playlist_id": playlist.id,
            }),
        })
        .then(data => {
            if (data.status === "success") {
                updateUser(data.user);
            }
        });
    };

    // Theme Change
    const handleThemeChange = (event) => {
        const theme = event.target.value; // INCOMPLETE TODO
        dispatch({
            type: "set_theme",
            theme: theme,
        });
        document.documentElement.setAttribute('data-theme', theme);
    };

    // Loading
    if (state.loading) {
        return (
            <div className="flex-grow flex justify-center items-center">
                <ClipLoader color="#AAAAAA" size="4rem" />
            </div>
        );
    }

    // Error
    if (state.error) {
        return (
            <div className="flex-grow flex justify-center items-center">
                <div className="text-error text-xl">Error fetching recent releases</div>
            </div>
        );
    }

    return (
        <div className="mx-5">
            <div className="text-5xl font-bold text-primary mb-2">Settings</div>
            <div className="italic">{user.name} (Spotify ID: {user.spotify_id})</div>

            <div className="divider" />

            <div>
                <div className="text-secondary font-bold text-2xl mb-2">Appearance</div>
                <div className="flex gap-5">
                    <div className="font-semibold flex items-center">Theme</div>
                    <div>
                        <select 
                            name="theme" 
                            className="border border-secondary bg-transparent p-1 rounded-full"
                            value={state.theme}
                            onChange={handleThemeChange}
                        >
                            <option value="light">light</option>
                            <option value="dark">dark</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="divider" />

            <div>
                <div className="text-secondary font-bold text-2xl mb-2">Recent Releases</div>
                <div className="flex gap-5">
                    <div className="font-semibold flex items-center">Release Playlist</div>
                    <div>
                        <select 
                            name="release-playlist" 
                            className="border border-secondary bg-transparent p-1 rounded-full"
                            value={user.settings.playlist_id ? user.settings.playlist_id : ""}
                            onChange={handlePlaylistChange}
                        >
                            { 
                                user.settings.playlist_id ?
                                    (state.playlists.length === 0 ? 
                                        <option value={user.settings.playlist_id}>{user.settings.playlist_name}</option>
                                        : null)
                                    : <option value="" disabled>No playlist selected</option> 
                            }
                            {
                                state.playlists.map((playlist) => {
                                    return <option key={playlist.id} value={playlist.id}>{playlist.name}</option>;
                                })
                            }
                        </select>
                        <span className="ml-2">
                            { user.settings.playlist_id ? <i className="icon-ok-circled text-emerald-600"></i> : <i className="icon-cancel-circled text-rose-600"></i> }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SettingsReducer = (state, action) => {
    switch (action.type) {
        case "fetch":
            return {
                ...state,
                loading: true,
            };
        case "success":
            return {
                ...state,
                loading: false,
                playlists: action.playlists,
            };
        case "error":
            return {
                ...state,
                loading: false,
                error: true,
            };
        case "set_theme":
            return {
                ...state,
                theme: action.theme,
            };
        default:
            throw new Error("Unknown action for SettingsReducer");
    }
};

export default Settings;