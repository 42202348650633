import { useAuth } from "../../Providers/AuthProvider";
import { useNotifications } from "../../Providers/NotificationProvider";

const ReleaseType = ({ type, releases, handleReleaseAdded }) => {
    const { user, request } = useAuth();
    const { setNotification } = useNotifications();

    const handleAdd = (index) => {
        if (!user.settings.playlist_id) {
            setNotification({
                type: "error",
                text: `Unable to add '${releases[index].name}'. Please select a playlist in settings.`,
            });
            return;
        }

        request("recents/releases", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: releases[index].id,
            }),
        })
        .then(data => {
            if (data.status === "success") {
                handleReleaseAdded(releases[index].id);
            }
        });
    };

    return (
        <div className="mb-5">
            <div className="text-2xl font-bold text-secondary border-b border-secondary pb-2 mb-4">{type}s</div>
            <div className="carousel carousel-end space-x-4">
                {
                    releases.map((release, index) => {
                        const artists = release.data.artists.map((artist, index) => {
                            return (
                                <span key={artist.id}>
                                    {index !== 0 && <span>, </span>}
                                    <span>{artist.name}</span>
                                </span>
                            );
                        });

                        return (
                            <div key={release.id} className="carousel-item">
                                <div className="card card-compact bg-base-200 shadow-lg w-36 relative">
                                    <figure className="w-36 h-36">
                                        <img className="rounded" src={release.data.images[1].url} />
                                    </figure>
                                    <div className="card-body">
                                        <div className="min-h-full flex flex-col justify-between">
                                            <div>
                                                <div className="text-sm font-bold">{release.data.name}</div>
                                                <div className="text-sm">{artists}</div>
                                            </div>
                                            <div className="flex justify-end">
                                                <span className="">
                                                    {
                                                        release.pivot.added ?
                                                            <i className="icon-ok-circled text-emerald-500"></i>
                                                            : <i className="icon-plus hover:text-slate-100" onClick={() => handleAdd(index)}></i>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default ReleaseType;