const PLAYLISTS = {
    "seasons": ["🌱", "🌞", "🍁", "❄️"],
    "energy": ["low", "medium", "high"],
    "activities": ["sleep", "work", "drive", "dinner", "cook", "exercise", "dance", "ski"],
    "moods": ["hype", "chill", "confident", "calm", "sad", "happy", "dark", "romantic", "angsty", "celebration", "angry"],
    "times": ["morning", "afternoon", "night", "late night"],
    "attributes": ["vocal", "instrumental", "acoustic", "funky", "glitchy", "bouncy", "international", "nostalgia", "banger", "spooky"],
    "colors": ["🔴", "🟠", "🟡", "🟢", "🔵", "🟣", "⚫️", "⚪️"],
    "settings": ["dinner party", "pre", "party", "beach", "apres-ski"],
    "dance": ["none", "nod", "sway", "groove", "bounce", "wild"]
};

export {
    PLAYLISTS,
};