import SpotifyWebApi from "spotify-web-api-js";
import { useAuth } from "../Providers/AuthProvider";
import { useCallback } from "react";

export const useSpotifyAPI = () => {
    // Auth
    const { user } = useAuth();

    // API
    const api = new SpotifyWebApi();
    api.setAccessToken(user.spotify_access_token);

    return {
        api,
        spotifyId: user.spotify_id,
    };
};

// TODO: I think this can be rebuild to fetch all at the same time by 1) getting number of tracks in playlist 2) requesting with promise.all
// TODO: Should probably turn toggles into remove + add
export const useSpotifyPlaylists = () => {
    // API
    const { api, spotifyId } = useSpotifyAPI();

    const isTrackInPlaylist = useCallback(async (trackId, playlistId) => {
        let options = {
            fields: ['items(track(id))', 'limit', 'next', 'offset'],
            limit: 50,
            offset: 0,
        };
    
        let tracks;
        do {
            tracks = await api.getPlaylistTracks(playlistId, options)
            const inPlaylist = tracks.items.reduce((acc, curr) => acc || curr.track.id === trackId, false);
    
            if (inPlaylist) {
                return true;
            }
    
            options.offset = tracks.offset + tracks.limit;
        } while (tracks.next);
    
        return false;
    }, [api]);

    const getPlaylistIdsFromNames = useCallback(async (playlistNames, type = null) => {
        const playlists = await getAllUserOwnedPlaylists();
    
        // Only return ids for playlists that exist
        const filteredPlaylists = playlists
            .filter((playlist) => {
                return playlistNames.includes(playlist.name) && (type === null || playlist.description.includes(`[${type}]`));
            })
            .map((playlist) => ({
                name: playlist.name,
                id: playlist.id,
            }));
    
        return filteredPlaylists;
    }, [api]);

    const getPlaylistsExistAndHaveTrack = useCallback(async (playlists, type, currentTrackId) => {
        // Get Playlist Ids
        return getPlaylistIdsFromNames(playlists, type)
            .then((existingPlaylistsWithIds) => {
                // Check each existing playlist to see if track is in there
                const playlistContainsTrackPromises = existingPlaylistsWithIds.map(async (playlist) => {
                    return {
                        name: playlist.name,
                        id: playlist.id,
                        hasTrack: await isTrackInPlaylist(currentTrackId, playlist.id),
                    };
                });
                return Promise.all(playlistContainsTrackPromises);
            })
            .then((playlistContainsTrackResults) => {
                // Combine results with all playlists (existing or not)
                const outputPlaylists = playlists.map((playlist) => {
                    const existingPlaylist = playlistContainsTrackResults.find((playlistWithId) => playlistWithId.name === playlist);
                    return {
                        type: type,
                        name: playlist,
                        id: existingPlaylist ? existingPlaylist.id : null,
                        hasTrack: existingPlaylist ? existingPlaylist.hasTrack : false,
                    };
                });

                return outputPlaylists;
            });
    }, [getPlaylistIdsFromNames, isTrackInPlaylist]);

    const findPlaylist = useCallback(async (playlistName, type = null) => {
        let options = {
            limit: 50,
            offset: 0,
        };
    
        var playlists;
        do {
            playlists = await api.getUserPlaylists(options);

            const playlist = playlists.items.find((playlist) => playlist.name === playlistName && (type === null || playlist.description.includes(type)));
            if (playlist) {
                return playlist;
            }
            
            options.offset = playlists.offset + playlists.limit;
        } while (playlists.next);

        return null;
    }, [api]);

    const createPlaylist = useCallback((playlistName, type = null) => {
        return api.createPlaylist(spotifyId, {
            name: playlistName,
            description: `${process.env.REACT_APP_NAME} Playlist${type === null ? null : ` [${type}]`}`,
        });
    }, [api]);

    const findOrCreatePlaylist = useCallback(async (playlistName, type = null) => {
        const playlist = await findPlaylist(playlistName, type);

        if (playlist === null) {
            return {
                type: "new",
                playlist: await createPlaylist(playlistName, type),
            };
        } else {
            return {
                type: "existing",
                playlist: playlist,
            };
        }
    }, [findPlaylist, createPlaylist]);

    const toggleTrackInPlaylist = useCallback(async (playlist, currentTrackId, playlistHasTrack) => {
        let playlistId = playlist.id;

        // Create Playlist If It Doesn't Exist
        if (playlist.id === null) {
            const foundOrCreatedPlaylist = await findOrCreatePlaylist(playlist.name, playlist.type);
            playlistId = foundOrCreatedPlaylist.playlist.id;
        }

        const trackUri = `spotify:track:${currentTrackId}`;

        if (playlistHasTrack) {
            return api.removeTracksFromPlaylist(playlistId, [trackUri]);
        } else {
            return api.addTracksToPlaylist(playlistId, [trackUri]);
        }
    }, [createPlaylist]);

    const isTrackLiked = useCallback((trackId) => {
        return api.containsMySavedTracks([trackId]);
    }, [api])

    const removeTrackLike = useCallback((trackId) => {
        return api.removeFromMySavedTracks([trackId]);
    }, [api]);

    const addTrackLike = useCallback((trackId) => {
        return api.addToMySavedTracks([trackId]);
    }, [api]);

    const toggleTrackLike = useCallback(async (trackId, newHearts, currentHearts = null) => {
        const isLiked = (await isTrackLiked(trackId))[0];

        if (currentHearts === newHearts && isLiked) {
            await removeTrackLike(trackId);
        } else if (currentHearts === null && !isLiked) {
            await addTrackLike(trackId);
        }

        // Remove Track From Old Playlist
        if (currentHearts !== null) {
            const oldPlaylist = {
                id: null,
                name: `${currentHearts}♥`,
                type: null,
            }
            await toggleTrackInPlaylist(oldPlaylist, trackId, true);
        }

        // Add Track To New Playlist
        if (currentHearts !== newHearts) {
            const newPlaylist = {
                id: null,
                name: `${newHearts}♥`,
                type: null,
            }
            await toggleTrackInPlaylist(newPlaylist, trackId, false);
        }
    }, [isTrackLiked, removeTrackLike, addTrackLike, toggleTrackInPlaylist]);

    const getAllUserOwnedPlaylists = useCallback(async () => {
        let options = {
            limit: 50,
            offset: 0,
        };
    
        let playlistResults;
        let playlists = [];
        do {
            playlistResults = await api.getUserPlaylists(options);
            playlists = playlists.concat(playlistResults.items);
    
            options.offset = playlistResults.offset + playlistResults.limit;
        } while (playlistResults.next);

        return playlists.filter(playlist => playlist.owner.id === spotifyId);
    }, [api]);

    return {
        isTrackInPlaylist,
        getPlaylistIdsFromNames,
        getPlaylistsExistAndHaveTrack,
        findPlaylist,
        findOrCreatePlaylist,
        toggleTrackInPlaylist,
        toggleTrackLike,
        getAllUserOwnedPlaylists,
    };
};