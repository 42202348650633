import { useRef, useEffect } from "react";

// useInterval Hook
const useInterval = (callback, ms) => {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {
        let interval = setInterval(() => savedCallback.current(new Date().getTime()), ms);
        return () => clearInterval(interval);
    }, []);
};

export { useInterval };