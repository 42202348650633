import { useEffect, useReducer, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useAuth } from "../../Providers/AuthProvider";
import ReleaseType from "./ReleaseType";

const Releases = () => {
    // State
    const [state, dispatch] = useReducer(ReleasesReducer, {
        loading: true,
        error: false,
        releases: [],
    });

    // Auth
    const { request } = useAuth();

    // useEffect - Fetch Releases
    useEffect(() => {
        dispatch({ type: "fetch" });

        request("recents/releases")
        .then(data => {
            dispatch({
                type: "success",
                releases: data,
            });
        })
        .catch(() => {
            dispatch({ type: "error" });
        });
    }, []);

    // Handle Adding Release
    const handleReleaseAdded = (id) => {
        // setReleases(releases.map((release) => {
        //     if (release.id === id) {
        //         release.pivot.added = true;
        //     }
        //     return release;
        // }));
    }

    // Loading
    if (state.loading) {
        return (
            <div className="flex-grow flex justify-center items-center">
                <ClipLoader color="#AAAAAA" size="4rem" />
            </div>
        );
    }

    // Error
    if (state.error) {
        return (
            <div className="flex-grow flex justify-center items-center">
                <div className="text-error text-xl">Error fetching recent releases</div>
            </div>
        );
    }

    // Get unique release types
    const types = [...new Set(state.releases.map(release => release.type))].sort();

    return (
        <div className="mx-5">
            <div className="text-5xl font-bold text-primary mb-5">Recent Releases</div>
            {
                types.map(type => {
                    const releasesInType = state.releases.filter(release => release.type === type);
                    return <ReleaseType key={type} type={type} releases={releasesInType} handleReleaseAdded={handleReleaseAdded} />;
                })
            }
        </div>
    );
};

const ReleasesReducer = (state, action) => {
    switch (action.type) {
        case "fetch":
            return {
                ...state,
                loading: true,
            };
        case "success":
            return {
                ...state,
                loading: false,
                releases: action.releases,
            };
        case "error":
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            throw new Error("Unknown action for ReleasesReducer");
    }
};

export default Releases;