import { useEffect, useState } from "react";
import { useAuth } from "../Providers/AuthProvider";

const Artists = ({ mode }) => {
    // State
    const [artists, setArtists] = useState([]);

    // Auth
    const { user, request } = useAuth();

    // useEffect
    useEffect(() => {
        request("artists")
        .then(data => {
            console.log(data);
            setArtists(data);
        })
    }, []);

    return (
        <div className="w-full">
            <div className="flex justify-between px-10 py-8">
                <div className="text-5xl font-bold text-slate-200">Artists</div>
            </div>
            <div className="w-full px-10 pb-10 flex flex-wrap gap-4">
                {
                    artists.map((artist) => {
                        return (
                            <div key={artist.id} className="group p-3 rounded bg-slate-700 hover:bg-slate-600" style={{ maxWidth: "150px" }}>
                                <div className="rounded border-4 border-slate-600 group-hover:border-slate-500">
                                    <img className="rounded h-32 w-32" src={artist.data.images[1]?.url} />
                                </div>
                                <div className="text-slate-300 text-sm font-bold mt-2">{artist.data.name}</div>
                                <div className="text-slate-500 text-xs mt-1">
                                    <span>{artist.pivot.num_tracks_saved} saved track{artist.pivot.num_tracks_saved === 1 ? "" : "s"}</span>
                                    <span className="italic"> ({Math.round(artist.pivot.num_tracks_saved / artist.num_tracks * 100)}%)</span>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default Artists;