import { Link } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";

const Navbar = () => {
    const { onLogout } = useAuth();

    return (
        <nav className="z-30 mx-2 my-5">
            <div className="navbar bg-base-300 rounded-full">
                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex="0" className="btn btn-ghost btn-circle">
                            <i className="icon-menu"></i>
                        </label>
                        <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-200 rounded-box w-52">
                            <li>
                                <Link to="/releases">Releases</Link>
                            </li>
                            <li>
                                <Link to="/classifier">Classifier</Link>
                            </li>
                            <li>
                                <Link to="/top">Top</Link>
                            </li>
                            <li>
                                <Link to="/artists">Artists</Link>
                            </li>
                            <li onClick={onLogout} className="text-error">
                                <div>Logout</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="navbar-center">
                    <a className="btn btn-ghost normal-case text-xl">{process.env.REACT_APP_NAME}</a>
                </div>
                <div className="navbar-end">
                    <Link to="/settings">
                        <button className="btn btn-ghost btn-circle">
                            <i className="icon-cog"></i>
                        </button>
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;