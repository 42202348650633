import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import { NotificationProvider } from "./NotificationProvider";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    // State
    const [user, setUser] = useState(null);

    // Cookies
    const [cookies, setCookie, removeCookie] = useCookies(["rr-api-token", "rr-api-user"]);

    // Navigation
    const navigate = useNavigate();

    // Set user from cookie
    if (!user) {
        if (cookies["rr-api-user"]) {
            setUser(cookies["rr-api-user"]);
        }
    }

    // Handle Login
    const handleLogin = (code) => {
        fetch(process.env.REACT_APP_CALLBACK_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                code: code,
            }),
        })
        .then(response => response.json())
        .then(data => {
            updateUser(data.user);
			setCookie("rr-api-token", data.token, {
				maxAge: 24 * 60 * 60,
				sameSite: "strict",
			});
			navigate("/");
		});
    };

    // Update User
    const updateUser = (user) => {
        setUser(user);
        setCookie("rr-api-user", user, {
            maxAge: 24 * 60 * 60, // 24 hour
            sameSite: "strict",
        });
    };

    // Handle Logout
    const handleLogout = () => {
        setUser(null);
        removeCookie("rr-api-token");
        removeCookie("rr-api-user");
        navigate("/");
    };

    // Request API Data
    const request = (urlFragment, options = {}) => {
        if (!options["headers"]) {
            options["headers"] = {};
        }
        options["headers"]["Authorization"] = cookies["rr-api-token"];
        return fetch(process.env.REACT_APP_API_URL + urlFragment, options)
                .then(res => res.json());
    };

    // Refresh User
    const refreshUser = () => {
        request("user/current")
            .then(data => setUser(data))
            .catch(() => {
                throw new Error("Error refreshing Spotify token.");
            });
    };

    // Create Provider Value
    const value = {
        user,
        onLogin: handleLogin,
        onLogout: handleLogout,
        request,
        updateUser,
        refreshUser,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook for using context
const useAuth = () => {
    return useContext(AuthContext);
};

// Authorized-only route
const ProtectedRoute = ({ redirectPath="/" }) => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    return (
        <NotificationProvider>
            <Layout>
                <Outlet />
            </Layout>
        </NotificationProvider>
    );
};

export { AuthProvider, useAuth, ProtectedRoute };