import Navbar from "./Navbar";
import Notifications from "./Notifications";

const Layout = ({ children }) => {
    return (
        <div className="flex flex-col min-h-screen mx-5">
            <Navbar />
            {children}
            <Notifications />
        </div>
    );
};

export default Layout;