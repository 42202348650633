import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Callback from "./Callback";
import Releases from "./Releases/Releases";
import { AuthProvider, ProtectedRoute } from "../Providers/AuthProvider";
import Artists from "./Artists";
import Error from "./Error";
import Classifier from "./Classifier/Classifier";
import Top from "./Top";
import Settings from "./Settings";

export default function App(props) {
	return (
		<AuthProvider>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/callback" element={<Callback />} />
				<Route element={<ProtectedRoute />}>
					<Route path="settings" element={<Settings />} />
					<Route path="releases" element={<Releases />} />
					<Route path="artists" element={<Artists />} />
					<Route path="classifier" element={<Classifier />} />
					<Route path="top" element={<Top />} />
				</Route>
				<Route path="*" element={<Error />} />
			</Routes>
		</AuthProvider>
	);
}