import { Navigate } from "react-router-dom";
import { useAuth } from "../Providers/AuthProvider";

const Login = (props) => {
    const { user } = useAuth();

    if (user) {
        return <Navigate to="/releases" replace />;
    }

    return (
        <div className="flex items-center justify-center h-screen">
            <div>
                <div className="font-bold text-xl mb-5 text-center">{process.env.REACT_APP_NAME}</div>
                <a 
                    href={process.env.REACT_APP_LOGIN_URL}
                    className="rounded-full bg-transparent border border-primary py-3 px-10 hover:bg-primary"
                >Login</a>
            </div>
        </div>
    );
}

export default Login;