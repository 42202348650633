import { useEffect, useRef, useReducer } from "react";
import { useAuth } from "../Providers/AuthProvider";

const useSpotifyPlaybackSDK = () => {
    // Ref
    const playerRef = useRef(null);
    const initializationRef = useRef(null);
    const isReadyRef = useRef(false);

    // State
    const [state, dispatch] = useReducer(SpotifyPlaybackSDKReducer, {
        currentTrackId: null,
        currentTrack: null,
        playback: null,
    });

    // Auth
    const { user, refreshUser } = useAuth();

    // useEffect
    useEffect(() => {
        // Add script tag
        if (!window.Spotify) {
            const scriptTag = document.createElement("script");
            scriptTag.src = "https://sdk.scdn.co/spotify-player.js";

            document.head.appendChild(scriptTag);

            // Initialize Player
            window.onSpotifyWebPlaybackSDKReady = () => {
                initializePlayer(true);
            };
        } else {
            initializePlayer();
        }

        // Disconnect Player on Unmount
        return () => {
            if (playerRef.current !== null) {
                playerRef.current.disconnect();
                playerRef.current = null;
                console.log("Disconnected Spotify player.");
            }
        };
    }, [user]);

    // Initialize Player
    const initializePlayer = (firstPageLoad = false) => {
        if (firstPageLoad && playerRef.current !== null) {
            return;
        }

        if (isReadyRef.current) {
            clearInterval(initializationRef.current);
            return;
        }

        if (window.Spotify) {
            playerRef.current = new window.Spotify.Player({
                name: process.env.REACT_APP_NAME,
                getOAuthToken: cb => { cb(user.spotify_access_token) },
            });
            addListenersToPlayer();

            if (initializationRef.current === null) {
                initializationRef.current = setInterval(initializePlayer, 5000);
            }
        }
    };

    // Add Listeners to Player
    const addListenersToPlayer = () => {
        const player = playerRef.current;

        // Connect
        player.connect();

        // Ready Listeners
        player.addListener('ready', handleReady);
        player.addListener('not_ready', handleNotReady);

        // Error Listeners
        player.addListener('initialization_error', handleError);
        player.addListener('authentication_error', handleError);
        player.addListener('account_error', handleError);

        // Change Listener
        player.addListener('player_state_changed', handleChangeState);
    };

    // Transfer Playback to Web SDK
    const transferPlayback = (deviceId) => {
        fetch("https://api.spotify.com/v1/me/player", {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${user.spotify_access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "device_ids": [deviceId], 
                "play": true
            }),
        })
        .then(response => {
            if (!response.ok) {
                console.error("Unable to transfer playback to Web SDK.");
            }
        });
    };

    // Handle Ready
    const handleReady = ({ device_id }) => {
        console.log('Ready with Device ID', device_id);
        isReadyRef.current = true;
        transferPlayback(device_id);
    };

    // Handle Not Ready
    const handleNotReady = ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
    };

    // Handle SDK Error
    const handleError = ({ message }) => {
        if (message === "Authentication failed") {
            console.log("Spotify token expired. Refreshing user.");
            refreshUser();
        }
    };

    // Handle Change State
    const handleChangeState = (data) => {
        dispatch({
            type: "change_state",
            playback: data,
        });
        if (state.currentTrack === null || data.track_window.current_track === null || state.currentTrack.id !== data.track_window.current_track.id) {
            dispatch({
                type: "change_track",
                playback: data,
            });
        }
    };

    return ({
        player: playerRef.current,
        playback: state.playback,
        currentTrack: state.currentTrack,
        currentTrackId: state.currentTrackId,
    });
};

const SpotifyPlaybackSDKReducer = (state, action) => {
    switch (action.type) {
        case "change_state":
            return {
                ...state,
                playback: action.playback,
            };
        case "change_track":
            return {
                ...state,
                currentTrackId: action.playback.track_window.current_track?.id,
                currentTrack: action.playback.track_window.current_track,
            };
        default:
            throw new Error("Undefined action for SpotifyPlaybackSDKReducer.");
    }
};

export { useSpotifyPlaybackSDK };