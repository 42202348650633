// TODO: Maybe move track-in-playlist determination into the ClassifierPlaylistButton component

import { memo, useEffect, useReducer, useState } from "react";
import SpotifyWebApi from "spotify-web-api-js";
import { useAuth } from "../../Providers/AuthProvider";
import { PLAYLISTS } from "./Playlists";
import { useSpotifyPlaylists, useSpotifyAPI } from "../../Hooks/useSpotify";
import { ClipLoader } from "react-spinners";

// ********** Components **********

export const ClassifierPlaylistButton = ({ playlist, currentTrackId }) => {
    // State
    const [state, dispatch] = useReducer(PlaylistButtonReducer, {
        hasTrack: playlist.hasTrack,
        loading: false,
        error: false,
    });

    // Playlists
    const { toggleTrackInPlaylist } = useSpotifyPlaylists();

    // Toggle Track in Playlist
    const toggleTrack = () => {
        dispatch({ type: "toggle" });
        
        toggleTrackInPlaylist(playlist, currentTrackId, state.hasTrack)
            .then(() => {
                dispatch({ type: "success" });
            })
            .catch(() => {
                // TODO: Add notification for error
                dispatch({ type: "error" });
            });
    };

    return (
        <button type="button" className={`btn btn-sm ${state.hasTrack ? "btn-primary" : "btn-outline"} ${playlist.id === null ? null : "btn-primary"}`} onClick={toggleTrack}>{playlist.name}</button>
    );
};

export const ClassifierLikes = memo(
    ({ currentTrackId }) => {
        // State
        const [state, dispatch] = useReducer(LikeReducer, {
            hearts: null,
            loading: true,
            error: false,
        });
    
        // Auth
        const { user } = useAuth();
    
        // Playlists
        const { findPlaylist, isTrackInPlaylist, toggleTrackLike } = useSpotifyPlaylists();
    
        // useEffect
        useEffect(() => {
            try {
                getTrackHearts(currentTrackId);
            } catch(err) {
                dispatch({ type: "error" });
            }
        }, [currentTrackId]);
    
        // Determine if Track is in Heart Playlists
        const getTrackHearts = async (trackId) => {
            const api = new SpotifyWebApi();
            api.setAccessToken(user.spotify_access_token);

            var qualityPlaylists = localStorage.getItem("qualityPlaylists");

            // Store Quality Playlists Upon Load
            if (state.loading) {
                qualityPlaylists = {};
                for (let i = 1; i <= process.env.REACT_APP_MAX_HEARTS; i++) {
                    const playlist = await findPlaylist(`${i}♥`);
                    qualityPlaylists[i] = playlist?.id;
                }
                localStorage.setItem("qualityPlaylists", JSON.stringify(qualityPlaylists));
            } else {
                qualityPlaylists = JSON.parse(qualityPlaylists);
            }

            for (let i = 1; i <= process.env.REACT_APP_MAX_HEARTS; i++) {
                if (qualityPlaylists[i] !== null) {
                    if (await isTrackInPlaylist(trackId, qualityPlaylists[i])) {
                        dispatch({
                            type: "success",
                            hearts: i,
                        });
                        return;
                    }
                }
            }
    
            dispatch({ type: "not_found" });
        };
    
        // Toggle Track Like + Heart Playlists
        const handleToggleLike = (event) => {
            const newHearts = event.target.dataset.heart;
            toggleTrackLike(currentTrackId, newHearts, state.hearts)
                .then(() => {
                    if (newHearts === state.hearts) {
                        dispatch({ type: "clear" });
                    } else {
                        dispatch({ // Gotta change from toggle to add/remove
                            type: "success",
                            hearts: newHearts,
                        });
                    }
                })
                .catch(() => {
                    dispatch({ type: "error" });
                });
        };
    
        // Loading
        if (state.loading) {
            return (
                <div className="flex justify-center">
                    <ClipLoader color="#AAAAAA" size="2rem" />
                </div>
            );
        }
    
        // Error
        if (state.error) {
            return (
                <div className="text-center text-error">An error occurred scanning quality playlists.</div>
            );
        }
    
        return (
            <div className="flex flex-row-reverse justify-center gap-8 text-xl text-slate-600">
                <button type="button" className={`peer hover:text-secondary ${state.hearts > 2 ? "text-secondary" : null}`}>
                    <i data-heart="3" className="icon-heart" onClick={handleToggleLike}></i>
                </button>
                <button type="button" className={`peer hover:text-secondary peer-hover:text-secondary ${state.hearts > 1 ? "text-secondary" : null}`}>
                    <i data-heart="2" className="icon-heart" onClick={handleToggleLike}></i>
                </button>
                <button type="button" className={`hover:text-secondary peer-hover:text-secondary ${state.hearts > 0 ? "text-secondary" : null}`}>
                    <i data-heart="1" className="icon-heart" onClick={handleToggleLike}></i>
                </button>
            </div>
        );
    },
    (n, p) => (n.currentTrackId === p.currentTrackId)
);

export const ClassifierArtistGenrePlaylists = memo(
    ({ current }) => {
        // State
        const [playlists, setPlaylists] = useState([]);
        const [loading, setLoading] = useState(true);

        // API
        const { api } = useSpotifyAPI();
        
        const artists = current.artists;

        // useEffect
        useEffect(() => {
            getArtistGenres();
        }, [artists]);

        // Get Artist Genres and Playlists
        const getArtistGenres = () => {
            setLoading(true);
            const artistIds = artists.map(artist => artist.uri.split(":")[2]);
            
            api.getArtists(artistIds)
                .then(results => {
                    let genreSet = new Set();
                    results.artists.forEach(artist => {
                        artist.genres.forEach(genreSet.add, genreSet);
                    });

                    setPlaylists([...genreSet]);
                    setLoading(false);
                })
                .catch(() => {
                    throw new Error("Error fetching artist genres.");
                });
        };

        return <ClassifierPlaylists title="artist genres" currentTrackId={current.id} playlistType="genre" playlists={playlists} loading={loading} />;
    },
    (n, p) => (n.current.id === p.current.id)
);

export const ClassifierGenrePlaylists = memo(
    ({ current }) => {
        // State
        const [playlists, setPlaylists] = useState([]);
        const [loading, setLoading] = useState(true);

        // Playlists
        const { getAllUserOwnedPlaylists } = useSpotifyPlaylists();

        // useEffect
        useEffect(() => {
            setLoading(true);
            getAllUserOwnedPlaylists()
                .then(allPlaylists => {
                    const genrePlaylists = allPlaylists.filter(playlist => playlist.description.includes("[genre]")).map(playlist => playlist.name);
                    setPlaylists(genrePlaylists);
                    setLoading(false);
                })
                .catch(() => {
                    throw new Error("Error fetching all genres.");
                });
        }, [current]);

        return <ClassifierPlaylists title="all genres" currentTrackId={current.id} playlistType="genre" playlists={playlists} loading={loading} allowAdd={true} sort={true} />;
    },
    (n, p) => (n.current.id === p.current.id)
);

export const ClassifierCustomPlaylists = memo(
    ({ currentTrackId }) => {
        const playlistTypes = Object.keys(PLAYLISTS);
        const randomType = playlistTypes[Math.floor(Math.random() * playlistTypes.length)];

        return <ClassifierPlaylists currentTrackId={currentTrackId} playlistType={randomType} playlists={PLAYLISTS[randomType]} />
    },
    (n, p) => (n.currentTrackId === p.currentTrackId)
);

export const ClassifierPlaylists = ({ title, currentTrackId, playlistType, playlists = [], loading = false, allowAdd = false, sort = false }) => {
    // State
    const [state, dispatch] = useReducer(PlaylistReducer, {
        playlistType: null,
        playlists: playlists,
        loading: true,
        error: false,
    });

    // Playlists
    const { getPlaylistsExistAndHaveTrack } = useSpotifyPlaylists();

    // useEffect
    useEffect(() => {
        dispatch({
            type: "fetch",
            playlistType: playlistType,
        });

        getPlaylistsExistAndHaveTrack(playlists, playlistType, currentTrackId)
            .then((playlists) => {
                dispatch({
                    type: "success",
                    playlists: sort ? playlists.sort((a, b) => {
                        if (a.name < b.name)
                            return -1;
                        if (a.name > b.name)
                            return 1;
                        return 0;
                    }) : playlists,
                });
            })
            .catch(() => {
                dispatch({
                    type: "error",
                });
            });
    }, [playlists]);

    // Add Playlist
    const addPlaylist = (playlist) => {
        dispatch({
            type: "add_playlist",
            playlist: playlist,
        });
    };

    // Content
    if (loading || state.loading) {
        var content = (
            <div className="flex justify-center">
                <ClipLoader color="#AAAAAA" size="2rem" />
            </div>
        );
    } else if (state.error) {
        var content = <div className="text-center">An error occurred loading playlists.</div>;
    } else {
        var content = (
            <div className="flex flex-wrap gap-2">
                {
                    state.playlists.length > 0 ?
                        state.playlists.map(playlist => <ClassifierPlaylistButton key={playlist.name} playlist={playlist} currentTrackId={currentTrackId} />) :
                        <div className="text-center">{`No ${state.playlistType} playlists found`}</div>
                }
            </div>
        );
    }

    return (
        <div className="card card-compact bg-base-200 shadow-xl overflow-visible">
            <div className="card-body">
                <div className="flex justify-between">
                    <div className="text-secondary card-title">{title ? title : state.playlistType}</div>
                    { allowAdd && <ClassifierAddPlaylist type={playlistType} addPlaylist={addPlaylist} /> }
                </div>
                {content}
            </div>
        </div>
    );
};

const ClassifierAddPlaylist = ({ type, addPlaylist }) => {
    // State
    const [state, dispatch] = useReducer(AddPlaylistReducer, {
        open: false,
        loading: false,
        error: false,
    });

    // Playlist
    const { findOrCreatePlaylist } = useSpotifyPlaylists();

    // Playlist Create
    const handlePlaylistCreate = (event) => {
        event.preventDefault();
        dispatch({ type: "add" });

        findOrCreatePlaylist(event.target.playlist.value.toLowerCase(), type)
            .then((playlist) => {
                if (playlist.type === "new") {
                    addPlaylist(playlist.playlist);
                }
                event.target.playlist.value = "";
                dispatch({ type: "success" });
            })
            .catch(() => dispatch({ type: "error" }));
    };

    return (
        <div className={`dropdown dropdown-end ${state.loading || state.error ? "dropdown-open" : null}`}>
            <label tabIndex={0} className="hover:text-secondary">
                <i className="icon-plus"></i>
            </label>
            <div tabIndex={0} className="dropdown-content card card-compact w-64 p-2 shadow bg-secondary text-secondary-content">
                <div className="card-body">
                    <h3 className="card-title">add {type} playlist</h3>
                    <form autoCapitalize="off" autoCorrect="off" spellCheck="false" onSubmit={handlePlaylistCreate}>
                        <div className="flex gap-2 border border-secondary-content rounded-full py-2 px-3">
                            <input 
                                name="playlist"
                                type="text" 
                                placeholder="playlist name"
                                className="bg-transparent flex-grow outline-none lowercase"
                                autoFocus
                                disabled={state.loading}
                                required
                                />
                            {
                                state.loading ?
                                    <div className="flex items-center">
                                        <ClipLoader size="1rem" />
                                    </div> :
                                    <button type="submit" className="hover:text-accent">
                                        <i className="icon-ok-circled text-xl"></i>
                                    </button>
                            }
                        </div>
                    </form>
                    { state.error && <div className="text-error text-center">Error creating playlist.</div> }
                </div>
            </div>
        </div>
    );
}

export const ClassifierPlaybackControls = ({ player, playback }) => {
    // State
    const [isShuffled, setIsShuffled] = useState(playback.shuffle);
    const [repeatType, setRepeatType] = useState(playback.repeat_mode); // 0 = none, 1 = context, 2 = track

    // API
    const { api } = useSpotifyAPI();

    // Play/Resume
    const play = () => {
        player.resume()
            .then(() => {
                document.title = playback.track_window.current_track.name;
            });
    };

    // Pause
    const pause = () => {
        player.pause()
            .then(() => {
                document.title = process.env.REACT_APP_NAME;
            });
    };

    // Skip Backwards
    const skipBackward = () => {
        player.previousTrack();
    };

    // Skip Forwards
    const skipForward = () => {
        player.nextTrack();
    };

    // Shuffle
    const shuffle = () => {
        api.setShuffle(!playback.shuffle);
        setIsShuffled(!playback.shuffle);
    };

    // Repeat
    const repeat = () => {
        const repeatStates = ["off", "context", "track"];
        const nextRepeat = (repeatType + 1) % 3;

        api.setRepeat(repeatStates[nextRepeat]);
        setRepeatType(nextRepeat);
    };

    return (
        <div className="flex justify-center gap-8 text-2xl">
            <button className="flex items-center" onClick={shuffle}>
                <i className={`text-sm icon-shuffle  hover:text-primary ${isShuffled ? "text-primary" : null}`}></i>
            </button>
            <button disabled={playback.disallows.skipping_prev} onClick={skipBackward}>
                <i className={`icon-to-start ${playback.disallows.skipping_prev ? null : "hover:text-primary"}`}></i>
            </button>
            <button disabled={playback.disallows.pausing}>
                {
                    playback.paused ?
                        <i className="icon-play hover:text-primary" onClick={play}></i> :
                        <i className={`icon-pause ${playback.disallows.pausing ? null : "hover:text-primary"}`} onClick={pause}></i>
                }
            </button>
            <button disabled={playback.disallows.skipping_next} onClick={skipForward}>
                <i className={`icon-to-end ${playback.disallows.skipping_next ? null : "hover:text-primary"}`}></i>
            </button>
            <button className="flex items-center" onClick={repeat}>
                <i className={`text-sm icon-exchange hover:text-primary ${repeatType === 0 ? null : (repeatType === 1 ? "text-primary" : "text-secondary")}`}></i>
            </button>
        </div>
    );
};

// ********** Reducers **********

const PlaylistButtonReducer = (state, action) => {
    switch (action.type) {
        case "toggle":
            return {
                ...state,
                loading: true,
                error: false,
            };
        case "success":
            return {
                ...state,
                hasTrack: !state.hasTrack,
                loading: false,
            };
        case "error":
            return {
                ...state,
                loading: false,
                error: true,
            }
        default:
            throw new Error("Unknown reducer state for PlaylistButtonReducer");
    }
};

const PlaylistReducer = (state, action) => {
    switch (action.type) {
        case "fetch":
            return {
                ...state,
                playlistType: action.playlistType,
                playlists: [],
                loading: true,
                error: false,
            };
        case "success":
            return {
                ...state,
                playlists: action.playlists,
                loading: false,
            };
        case "error":
            return {
                ...state,
                loading: false,
                error: true,
            }
        case "add_playlist":
            return {
                ...state,
                playlists: [...state.playlists, action.playlist],
            };
        default:
            throw new Error("Unknown reducer state for PlaylistReducer");
    }
};

const LikeReducer = (state, action) => {
    switch (action.type) {
        case "fetch":
            return {
                ...state,
                hearts: null,
                loading: true,
                error: false,
            };
        case "success":
            return {
                ...state,
                hearts: action.hearts,
                loading: false,
            };
        case "clear":
            return {
                ...state,
                hearts: null,
                loading: false,
            };
        case "not_found":
            return {
                ...state,
                hearts: null,
                loading: false,
            };
        case "error":
            return {
                ...state,
                loading: false,
                error: true,
            }
        default:
            throw new Error("Unknown reducer state for LikeReducer");
    }
};

const AddPlaylistReducer = (state, action) => {
    switch (action.type) {
        case "add":
            return {
                ...state,
                loading: true,
            };
        case "success":
            return {
                ...state,
                loading: false,
            };
        case "error":
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            throw new Error("Unknown reducer state for AddPlaylistReducer");
    }
};