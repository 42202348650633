import { useState } from "react";
import { useNotifications } from "../../Providers/NotificationProvider";

const Notifications = () => {
    const { notifications, clearNotifications } = useNotifications();

    return (
        <div className="fixed top-0 right-0 mt-20 mr-3 flex flex-col-reverse gap-y-2">
            {
                notifications.reverse().map((notification) => {
                    return <Notification key={notification.id} notification={notification} handleRemove={() => clearNotifications(notification.id)} />;
                })
            }
        </div>
    );
};

const notificationTypeColor = {
    "error": "bg-rose-600",
    "success": "bg-emerald-600",
    "default": "bg-blue-600",
};

const Notification = ({ notification, handleRemove }) => {
    const [hover, setHover] = useState(false);

    const opacity = 0.6; // Add some fading for older notifications
    return (
        <div 
            className={`relative px-4 py-3 rounded text-white text-xs w-48 border border-white opacity-60 shadow-black shadow-sm hover:opacity-90 ${notificationTypeColor[notification.type]}`}
            style={{ opacity: hover ? 0.9 : opacity }}
            onClick={handleRemove}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {notification.text}
            <span className="absolute top-0 right-0 -mr-1 -mt-2">
                <i className="icon-cancel-circled"></i>
            </span>
        </div>
    );
};

export default Notifications;