import { useSearchParams } from "react-router-dom";
import { useAuth } from "../Providers/AuthProvider";

const Callback = (props) => {
    const { onLogin } = useAuth();
    const [searchParams, ] = useSearchParams();
    const code = searchParams.get("code");
    onLogin(code);
    return null;
}

export default Callback;